<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"  ref="right" :taskId.sync="taskId" :taskBottomtype.sync="taskBottomtype" :showAllTask.sync="showAllTask"></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          :is="curBottomComponent"
           @on-changeTask="changeTask"
           @on-changeShowAllTask="changeShowAllTask"
           @on-reloadRight="reloadRight"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/Bottom'),
    Right: () => import('./components/Right'),
    Left: () => null
  },
  data () {
    return {
      taskId: 0,
      taskBottomtype: 1,
      showAllTask: false
    }
  },
  methods: {
    changeTask (id, bottomtype) {
      this.taskId = id
      this.taskBottomtype = bottomtype
    },
    changeShowAllTask (showAllTask) {
      this.showAllTask = showAllTask
    },
    reloadRight (id) {
      this.taskId = id
      this.$refs.right.reload()
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(true)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
    this.setRightComponent('Right')
  }
}
</script>
